// Pricing.js
import React from 'react';

const Pricing = () => {
    return (
        <section id="pricing" className="pricing">
            <h2>Wash & Fold Drop Off</h2>
            
            <div className="services-list">
                <div className="service">
                    <h3>Regular Service</h3>
                    <p>Pick up next day</p>
                    <p>$1.50 per pound</p>
                    <p className="italics">*$20 minimum</p>
                </div>
                <div className="service">
                    <h3>Express Service</h3>
                    <p>Pick up after 8 hours</p>
                    <p>$1.75 per pound</p>
                    <p className="italics">*$20 minimum</p>
                </div>
            </div>
        </section>
    );
};

export default Pricing;
