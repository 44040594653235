import React from 'react';
import WaterMachineImg from '../images/water-machine.jpg';

const Flyers = () => {
    return (
        <section id="flyers">
            <h2>Promotion</h2>

            <div className="flyers-container">
                <div className="flyer-image-container">
                    <img src={WaterMachineImg} alt="promotion water machine" />
                </div>
                <div className="flyer-text-container">
                    <h2>Free 5 Gallon Water!</h2>
                    <p>Visit for our Fresh Drinking Water! We offer one free 5 gallon water for free for our wash customers (limited time offer, water bottle not included).</p>
                </div>
            </div>
        </section>
    );
};

export default Flyers;
