// Header.js
import React from 'react';
import logo from '../images/wash-bros-logo.png';
import AnchorLink from 'react-anchor-link-smooth-scroll'; 

const Header = () => {
    return (
        <header className="header">
            <div className="header-top">
                <div className="header-top-logo">
                    <div className="logo-container">
                        <img src={logo} alt="Wash Bros Logo" className="logo" />
                    </div>
                    <h1>Wash Bros Laundromat</h1>
                </div>

                <div className="header-link hide-mobile">
                    <div className="">
                        <a className="header-link-child" href="tel:3237442527">
                            <svg fill="#007bff" viewBox="0 0 24 24" width="16" height="16">
                                <path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z"/>
                            </svg>
                            <span><a href="tel:+3237442527">(323) 744-2527</a></span>
                        </a>
                        <div className="header-link-child">
                            <svg fill="#007bff" width="16" height="16" viewBox="0 0 128 128">
                                <path d="M 64 13.5 C 36.2 13.5 13.5 36.2 13.5 64 C 13.5 91.8 36.2 114.5 64 114.5 C 91.8 114.5 114.5 91.8 114.5 64 C 114.5 36.2 91.8 13.5 64 13.5 z M 64 19.5 C 88.5 19.5 108.5 39.5 108.5 64 C 108.5 88.5 88.5 108.5 64 108.5 C 39.5 108.5 19.5 88.5 19.5 64 C 19.5 39.5 39.5 19.5 64 19.5 z M 64 31 C 62.3 31 61 32.3 61 34 L 61 58.800781 C 59.2 59.800781 58 61.8 58 64 C 58 67.3 60.7 70 64 70 C 66.2 70 68.199219 68.8 69.199219 67 L 84 67 C 85.7 67 87 65.7 87 64 C 87 62.3 85.7 61 84 61 L 69.199219 61 C 68.699219 60.1 67.9 59.300781 67 58.800781 L 67 34 C 67 32.3 65.7 31 64 31 z"></path>
                            </svg>

                            <span>Open 24 Hours</span>
                        </div>
                    </div>
                    
                    <a 
                        className="header-link-child"
                        href="https://maps.app.goo.gl/xqPvv3FtEc2vTXjT9" 
                        target="_blank" 
                        rel="noopener noreferrer" >
                        <span><svg fill="#007bff" version="1.1" id="Capa_1" width="16px" height="16px" viewBox="0 0 395.71 395.71" ><g>
                        <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
                            c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
                            C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
                            c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"/></g></svg></span>
                        <span>8936 State St,<br></br> South Gate, CA 90280</span>
                    </a>
                </div>
            </div>

            <nav className="main-nav">
                <ul>
                    <li><AnchorLink href="#services">Self Service</AnchorLink></li>
                    <li><AnchorLink href="#pricing">Wash & Fold</AnchorLink></li>
                    <li><AnchorLink href="#location">Location</AnchorLink></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;