import React from 'react';
import MainImg from '../images/washbros-gallery-3.jpeg';

const IntroductionBanner = () => {
    return (
        <section 
            id="introduction" 
            className="introduction-banner"
            style={{ background: `url(${MainImg}) no-repeat center center`, backgroundSize: `cover` }}
        >
        </section>
    );
};

export default IntroductionBanner;
