import React from 'react';

const Location = () => {
    return (
        <section id="location">
            <h2>Location</h2>

            <div className="location">
                <iframe className="google-map" title="location-google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105862.35001608009!2d-118.31218346093749!3d33.9874364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c95608c3a457%3A0x4133e2074d1d9bd5!2sWash%20Bros%20Laundromat!5e0!3m2!1sen!2sus!4v1700888344332!5m2!1sen!2sus" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                
                <div className="location-container">
                    <div className="location-address">
                        <h4>8936 State St,<br></br> South Gate, CA 90280</h4>
                        <a href="https://maps.app.goo.gl/xqPvv3FtEc2vTXjT9" target="_blank" rel="noopener noreferrer" className="map-address-link">
                        <svg width="16px" height="16px" viewBox="0 0 18 18" mirror-in-rtl="true">
                            <path fill="#007bff" d="M12.1.6a.944.944 0 0 0 .2 1.04l1.352 1.353L10.28 6.37a.956.956 0 0 0 1.35 1.35l3.382-3.38 1.352 1.352a.944.944 0 0 0 1.04.2.958.958 0 0 0 .596-.875V.96a.964.964 0 0 0-.96-.96h-4.057a.958.958 0 0 0-.883.6z"/>
                            <path fill="#007bff" d="M14 11v5a2.006 2.006 0 0 1-2 2H2a2.006 2.006 0 0 1-2-2V6a2.006 2.006 0 0 1 2-2h5a1 1 0 0 1 0 2H2v10h10v-5a1 1 0 0 1 2 0z"/>
                        </svg>
                            Get directions
                        </a>
                    </div>
                    <div className="location-hours"> 
                        <h4>Hours</h4>
                        <div>
                            <p>Open 24 Hours</p>
                            <p>Monday - Sunday</p>
                            <p className="italics">*Closed on Christmas Day</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Location;
