import React from 'react';
import YelpImg from '../images/yelp.png';
import GoogleReviewsImg from '../images/google-reviews.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="social-links">
                <a href="https://www.yelp.com/biz/wash-bros-laundromat-south-gate" className="social-link" target="_blank" rel="noreferrer">
                    <img src={YelpImg} alt="yelp logo"/>
                </a>
            </div>
            <div className="social-links">
                <a href="https://maps.app.goo.gl/pkR3nHMzGQKTyhsj8" className="social-link" target="_blank" rel="noreferrer">
                    <img src={GoogleReviewsImg} alt="google reviews logo"/>
                </a>
            </div>
            
            <div className="footer-copyright-container">
                <address>8936 State St, South Gate, CA 90280</address>
                <p className="copyright">&copy; 2023 Wash Bros Laundromat</p>
            </div>
        </footer>
    );
};

export default Footer;
